#nav-customise {
	border-left: 2px solid #f5f5f5;
}
.heading-text {
	font-size: 14px !important;
	font-weight: bold !important;
}
.notification-text {
	font-size: 24px !important;
}
.fs-14 {
	font-size: 14px !important;
}

.fs-13 {
	font-size: 13px !important;
}
.fs-13-mid {
	font-size: 13.5px !important;
}
.f-18 {
	font-size: 18px !important;
}

.recommendations.card {
	min-height: 135px;
}

.widget-displayed-number {
	font-size: 40px;
	font-weight: 500;
}
.widget-displayed-text {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	    align-items: center;
}

/* Reports table */
.info-box-4.re-widget-card {
	display: block;
	min-height: 110px;
	margin-bottom: 8px;
	padding: 8px 16px;
	overflow: visible;
}
.info-box-4.re-widget-card.large {
	min-height: 90%;
}
.info-box-4.re-widget-card .text {
	margin-top: 8px;
}
.info-box-4.re-widget-card .content .text {
	font-size: 14px;
}
.re-widget-card-number {
	font-size: 24px;
	font-weight: bold !important;
	margin-top: 4px !important;
}
.re-widget-card-indicator {
	font-size: 10px;
	font-weight: bold;
}
.re-widget-card-indicator.large {
	font-size: 20px;
	font-weight: bold;
}
.recommendations-reports-table {
	border-collapse: collapse;
}
.recommendations-reports-table th {
	background-color: #e9e9e9;
}
.recommendations-reports-table td,
.recommendations-reports-table th {
	border: 1px solid #ddd;
	padding: 8px;
	font-size: 12px;
}
.info-box-4.re-widget-card {
	-ms-flex-pack: justify;
	    justify-content: space-between;
}
.re-widget-card-image {
	-ms-flex-item-align: end;
	    align-self: flex-end;
	text-align: end;
}
.re-widget-card-image img {
	width: 96px;
}
.card .header.re-chart {
	border-bottom: 0;
	padding: 0;
	-ms-flex: 1 1;
	    flex: 1 1;
}
.re-chart-select {
	min-width: 130px;
}
.re-chart-select .Select-value {
	line-height: 30px !important;
	font-size: 12px;
}
.re-chart-select .Select-control,
.re-chart-select .Select-input {
	height: 30px;
}

/* Offer Options */
.offer-selection-button {
	border-color: #000;
	border-width: 1px;
	height: 120px;
	width: 120px;
}
/* .offer-selection-button:hover {
  color: #378080;
  font-weight: bold;
  border: 2px solid #378080;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.233);
} */
.offer-selection-button-selected {
	color: #378080;
	font-weight: bold;
	border: 1px solid #378080;
	height: 120px;
	width: 120px;
	-webkit-box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.233);
	        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.233);
}

.re-widget-checkbox {
	border-color: #378080;
}

.re-widget-settings-content {
	min-width: 900px !important;
	margin-bottom: 0;
}
.card.re-widget-settings-overlay {
	margin-bottom: 0;
}

.step-text {
	font-size: 16px;
	font-weight: bold;
}

a.btn-basic-settings {
	color: #b6b6b6 !important;
	font-size: 14px;
	font-weight: bold;
	padding-bottom: 4px;
	cursor: pointer;
}
a.btn-basic-settings:hover {
	border-bottom: 3px solid #378080;
}
a.btn-basic-settings.active {
	color: #333333 !important;
	border-bottom: 3px solid #378080;
}
a.btn-custom-settings {
	color: #b6b6b6 !important;
	font-size: 14px;
	font-weight: bold;
	padding-bottom: 4px;
	cursor: pointer;
}
a.btn-custom-settings:hover {
	border-bottom: 3px solid #378080;
}
a.btn-custom-settings.active {
	color: #333333 !important;
	border-bottom: 3px solid #378080;
}

.recommendations-checkbox-text {
	font-size: 14px !important;
}
#re-custom-styles-input {
	height: 300px;
	width: 100%;
}

.re-settings-table td {
	padding-bottom: 8px;
}

.re-widget-info-icon {
	color: #b6b6b6;
	font-size: 14px;
	vertical-align: middle;
}
.re-widget-info-icon.large {
	font-size: 16px;
}

/* Color picker */
.color-picker-color {
	width: 36px;
	height: 14px;
	border-radius: 2px;
}
.color-picker-swatch {
	height: -webkit-fit-content;
	height: -moz-fit-content;
	height: fit-content;
	padding: 5px;
	background: #fff;
	border-radius: 1px;
	-webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
	        box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
	display: inline-block;
	cursor: pointer;
}
.color-picker-popover {
	position: absolute;
	z-index: 2;
}
.color-picker-cover {
	position: fixed;
	top: 0px;
	right: 0px;
	bottom: 0px;
	left: 0px;
}

/* Preview */
.sfdr-recommendations-preview-container {
	padding: 0 32px;
}

.sfdr-recommendation-products-title {
	margin-top: 1rem;
}

.sfdr-recommendation-products-container {
	display: block;
	overflow: auto;
	white-space: nowrap;
}

.sfdr-recommendation-product-container {
	display: inline-block;
	width: 255px;
	margin: 0.8rem;
	position: relative;
}
.sfdr-dd-widget-preview-container {
	/* display: inline-block; */
	/* width: 100%; */
	height: 500px;
	/* margin: 0.8rem; */
	/* position: relative; */
	border: 2px solid #46a0a6;
	/* background: #ffffff url("./preview-bg.png") no-repeat right top; */
}

.sfdr-recommendation-product-image {
	display: grid;
	max-height: 255px;
	max-width: 255px;
}

.sfdr-recommendation-product-image img {
	position: relative;
	-o-object-fit: contain;
	   object-fit: contain;
	height: 100%;
	width: 100%;
	max-height: 255px;
	max-width: 255px;
}

.sfdr-recommendation-product-name {
	font-size: 0.8rem;
	-o-text-overflow: ellipsis;
	   text-overflow: ellipsis;
	overflow: hidden;
	height: 3em;
	line-height: 1.5em;
	white-space: normal;
	margin-top: 8px;
	width: 90%;
}

.sfdr-recommendation-product-badge {
	position: absolute;
	top: 0;
	right: 0;
	width: 40px;
	height: 40px;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	    align-items: center;
	-ms-flex-pack: center;
	    justify-content: center;
	border-radius: 100%;
	background: #dc3545;
	font-size: 14px;
	color: #ffffff;
}

.sfdr-recommendation-product-price {
	font-size: 1rem;
	font-weight: 600;
	padding-top: 8px;
}
.sfdr-recomendation-product-price-discount-container {
	display: -ms-flexbox;
	display: flex;
}
.sfdr-recommendation-product-price-strikethrough {
	font-size: 14px;
	font-weight: 600;
	padding-top: 8px;
	text-decoration: line-through;
}
.sfdr-recommendation-product-price-discount {
	font-size: 14px;
	font-weight: 600;
	padding-top: 8px;
	color: #dc3545;
}

.sfdr-recommendation-product-button-container {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: center;
	    justify-content: center;
	margin-top: 8px;
}

.sfdr-recommendation-product-button {
	color: #fff;
	border: none;
	border-radius: 4px;
	font-size: 0.9rem;
	font-weight: 600;
	height: 32px;
	min-width: 70%;
	padding: 0 8px;
}

.sfdr-recommendations-customise-select {
	border: 0;
	border-bottom: 1px solid #ebebeb;
}

.font-xs {
	font-size: 9.5px;
}
.count-text {
	font-size: 11px;
}

#re-notification-text-area {
	resize: none;
	/* height: 90px; */
	/* max-height: 80px; */
	/* width: 100%; */
	border: solid 1px #46a0a6;
}
.input-group-prepend {
	width: 20%;
}

.input-group-prepend label {
	width: 100%;
	overflow: hidden;
}

.link {
	color: #46a0a6;
	cursor: pointer;
	font-weight: 400;
	text-decoration: none;
}

.link--arrowed {
	display: inline-block;
	height: 2rem;
	line-height: 2rem;
}
.link--arrowed .arrow-icon {
	position: relative;
	top: -1px;
	-webkit-transition: -webkit-transform 0.3s ease;
	transition: -webkit-transform 0.3s ease;
	-o-transition: transform 0.3s ease;
	transition: transform 0.3s ease;
	transition: transform 0.3s ease, -webkit-transform 0.3s ease;
	vertical-align: middle;
}
.link--arrowed .arrow-icon--circle {
	-webkit-transition: stroke-dashoffset 0.3s ease;
	-o-transition: stroke-dashoffset 0.3s ease;
	transition: stroke-dashoffset 0.3s ease;
	stroke-dasharray: 95;
	stroke-dashoffset: 95;
}
.link--arrowed:hover .arrow-icon {
	-webkit-transform: translate3d(5px, 0, 0);
	        transform: translate3d(5px, 0, 0);
}
.link--arrowed:hover .arrow-icon--circle {
	stroke-dashoffset: 0;
}
.option-2 {
	text-decoration: underline;
	color: blue;
}
.reconfigure-setup-button {
	color: #f9b32a;
	border-color: #f9b32a;
	border-width: 2px;
	height: 112px;
	width: 112px;
}
.reconfigure-setup-button:hover {
	color: #f9b32a;
	font-weight: bold;
	-webkit-box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.233);
	        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.233);
}
.permissions-icon {
	font-size: 3em !important;
}
.permissions-card {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	min-width: 0;
	word-wrap: break-word;
	position: relative;
	/* border: #000 solid 2px; */
}
.fs-12 {
	font-size: 12px !important;
}

.plan-price {
	font-family: 'Roboto';
	font-size: 24px;
	font-weight: 400;
}

.plan-price-unit {
	margin-right: 1px;
}
.fs-16 {
	font-size: 16px;
}

.small {
	font-size: 14px;
	font-weight: 400;
}
.medium {
	font-size: 75%;
	font-weight: 400;
}
.medium-bold{
	font-size: 75%;
	font-weight: bold;
}
.display-type{
	width: 80px;
	height: auto;
}
.test-p-overlay{
	background: transparent !important;
	height: 0 !important;
	width: 0 !important;
	z-index: -999 !important;
}

.test-p-content{
	background: transparent !important;
}


