@charset "UTF-8";
:root {
  --s-width: 900px;
  --s-gutter: 2.5rem;
  --c-accent: hsl(213, 74%, 58%);
}

.stepper {
  --s-stepper-bullet: 2rem;
  --s-stepper-bullet-half: calc( var(--s-stepper-bullet) / 2 );
  --step-transition: background .5s, color .5s;
  --step-content: '✔︎';
  --step-color: hsl(0, 0%, 70%);
  --step-bar-bg: var(--c-accent);
  --step-bullet-bg: var(--step-bar-bg);
  --step-bullet-color: white;
  counter-reset: current-step;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(1px, 1fr));
  position: relative;
  z-index: 1;
}


.stepper__input {
  counter-increment: steps;
  display: none;
}
.stepper__step {
  counter-increment: current-step;
}
.stepper__input:checked ~ .stepper__step {
  --step-color: hsl(0, 0%, 30%);
  --step-bar-bg: hsl(0, 0%, 40%);
  --step-bullet-bg: var(--step-bar-bg);
  --step-bullet-color: hsl(0, 0%, 20%);
  --step-content: counter(current-step);
}
.stepper__input:checked ~ .stepper__step .stepper__content {
  opacity: 0;
  pointer-events: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.stepper__input:checked + .stepper__step {
  --step-bullet-bg: hsl(213, 70%, 50%);
  --step-bullet-color: white;
  --step-color: black;
}
.stepper__input:checked + .stepper__step .stepper__button::before {
  -webkit-box-shadow: 0 0 0 2px #b3b3b3;
          box-shadow: 0 0 0 2px #b3b3b3;
}
.stepper__input:checked + .stepper__step .stepper__content {
  opacity: 1;
  pointer-events: auto;
  -webkit-user-select: auto;
     -moz-user-select: auto;
      -ms-user-select: auto;
          user-select: auto;
}
.stepper__content {
  color: white;
  text-align: center;
  font-style: italic;
  font-weight: 300;
  color: var(--step-color);
  -webkit-transition: opacity .5s .05s;
  -o-transition: opacity .5s .05s;
  transition: opacity .5s .05s;
  padding: .5rem;
}
.stepper__content::-moz-selection {
  color: black;
  background: var(--step-bullet-color);
}
.stepper__content::selection {
  color: black;
  background: var(--step-bullet-color);
}
.stepper__button {
  position: relative;
  text-align: center;
  color: var(--step-color);
  display: block;
}
.stepper__button::before {
  content: var(--step-content);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  margin: 0 auto var(--s-stepper-bullet-half);
  height: var(--s-stepper-bullet);
  width: var(--s-stepper-bullet);
  border-radius: var(--s-stepper-bullet);
  -webkit-transition: var(--step-transition);
  -o-transition: var(--step-transition);
  transition: var(--step-transition);
  background: var(--step-bullet-bg);
  color: var(--step-bullet-color);
}
.stepper__button::after {
  content: '';
  position: absolute;
  width: 100%;
  height: calc( var(--s-stepper-bullet-half) / 2 );
  background: var(--step-bar-bg);
  -webkit-transition: var(--step-transition);
  -o-transition: var(--step-transition);
  transition: var(--step-transition);
  top: var(--s-stepper-bullet-half);
  left: 50%;
  -webkit-transform: translate(0, -50%);
          -ms-transform: translate(0, -50%);
      transform: translate(0, -50%);
  z-index: -1;
}

.stepper__step:last-child .stepper__button::after {
  display: none;
}
.stepper--flexbox {
  display: -ms-flexbox;
  display: flex;
}
.stepper--flexbox .stepper__step {
  -ms-flex-positive: 1;
      flex-grow: 1;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  -ms-flex-preferred-size: 0;
      flex-basis: 0;
}

/* #sectionTabs {
  font-size: 0.8em;
  position: relative;
  padding: 10px;
  font-weight: bold;
  list-style: none;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(1px, 1fr));
  text-transform: uppercase;
}
#sectionTabs li {
  -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.16), 0 2px 10px rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.16), 0 2px 10px rgba(0, 0, 0, 0.12);
  color: #4b4545;
  cursor: pointer;
  border-left: 1px solid #aaa;
  text-decoration: none;
  padding: 0 6px;
  float: left;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  text-align: center;
  font-weight: bold;
  line-height: 40px;
  background: #eff1f5;
  position: relative;
  border-radius: 2px;
}
#sectionTabs li:hover {
  background: #5f676e;
  color: #ffffff;
}
#sectionTabs li span {
  color: #bababa;
}
#sectionTabs li.active {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.16), 0 2px 10px rgba(0, 0, 0, 0.12);
  color: #444;
  cursor: pointer;
}
#sectionTabs li:after {
  content: "";
  display: block;
  margin-left: 0;
  position: absolute;
  left: 0;
  top: 0;
}
#sectionTabs li.current {
  -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.16), 0 2px 10px rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.16), 0 2px 10px rgba(0, 0, 0, 0.12);
  opacity: 1;
  background: #1f91f3;
  z-index: 999;
  border-left: none;
  color: #fff;
  border-radius: 4px;
}
#sectionTabs li.current:after {
  border: 20px solid transparent;
  border-left: 20px solid #ffffff;
  border-radius: 2px;
} */

#sectionTabs {
  font-size: 0.7em;
  position: relative;
  padding: 10px;
  font-weight: bold;
  list-style: none;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(1px, 1fr));
  text-transform: uppercase;
}
#sectionTabs li {
  -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.16), 0 2px 10px rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.16), 0 2px 10px rgba(0, 0, 0, 0.12);
  color: #4b4545;
  cursor: pointer;
  text-decoration: none;
  padding: 8px;
  float: left;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  text-align: center;
  /* font-weight: bold; */
  /* line-height: 40px; */
  background: #eff1f5;
  position: relative;
  border-radius: 2px;
}
#sectionTabs li:hover {
  background: #5f676e;
  color: #ffffff;
}
/* #sectionTabs li span {
  color: #bababa;
} */
#sectionTabs li.active {
  -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.16), 0 2px 10px rgba(0, 0, 0, 0.12);
          box-shadow: 0 2px 5px rgba(0, 0, 0, 0.16), 0 2px 10px rgba(0, 0, 0, 0.12);
  color: #444;
  cursor: pointer;
}
#sectionTabs li:after {
  content: "";
  display: block;
  margin-left: 0;
  position: absolute;
  left: 0;
  top: 0;
}
#sectionTabs li.current {
  -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.16), 0 2px 10px rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.16), 0 2px 10px rgba(0, 0, 0, 0.12);
  opacity: 1;
  background: #35B7E6;
  z-index: 999;
  border-left: none;
  color: #fff;
  border-radius: 4px;
}

.btnss {
  -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.16), 0 2px 10px rgba(0, 0, 0, 0.12);
          box-shadow: 0 2px 5px rgba(0, 0, 0, 0.16), 0 2px 10px rgba(0, 0, 0, 0.12);
  border-radius: 2px;
  border: none;
  font-size: 13px;
  outline: none;
  color: #fff;
  background-color: #1f91f3;
  padding: 10px 16px;
  line-height: 1.3333333;
  display: inline-block;
  padding: 6px 12px;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
  overflow: visible;
  margin: 0;
}
.btnss:hover {
  background: #5f676e;
}
.next{
  float: right !important;
}
.previous{
 float: left !important;
}

.example-enter {
  /* Prevent some white flashing in Safari 5.1 */
  -webkit-backface-visibility: hidden;
  border-radius:         20px;

  opacity: 0;

  -webkit-transform: scale(0) skew(50deg);
  -moz-transform:    scale(0) skew(50deg);
  -ms-transform:     scale(0) skew(50deg);
  -o-transform:      scale(0) skew(50deg);

  -webkit-transform-origin: 0px -30px;
  -moz-transform-origin:    0px -30px;
  -ms-transform-origin:     0px -30px;
  -o-transform-origin:      0px -30px;

  -webkit-transition: -webkit-transform ease-out .55s, opacity ease-out .8s;
  -moz-transition:    -moz-transform    ease-out .55s, opacity ease-out .8s;
  -ms-transition:     -ms-transform     ease-out .55s, opacity ease-out .8s;
  -o-transition:      -o-transform      ease-out .55s, opacity ease-out .8s;
}

.example-enter.example-enter-active {
  opacity: 1;
  -webkit-transform: scale(1) skew(0deg);
  -moz-transform:    scale(1) skew(0deg);
  -ms-transform:     scale(1) skew(0deg);
  -o-transform:      scale(1) skew(0deg);
}

.example-exit {

}

.example-exit.example-exit-active {

}

.example1-enter {
  overflow:hidden;
  -webkit-transform:translate(-100%);
      -ms-transform:translate(-100%);
          transform:translate(-100%)

}

.example1-enter.example-enter-active {
  overflow:hidden;
  -webkit-transition:-webkit-transform 0.3s ease-out;
  transition:-webkit-transform 0.3s ease-out;
  -o-transition:transform 0.3s ease-out;
  transition:transform 0.3s ease-out;
  transition:transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform:translate(-100%);
      -ms-transform:translate(-100%);
          transform:translate(-100%);
  -webkit-transform-origin:top;
      -ms-transform-origin:top;
          transform-origin:top;
}

.example1-exit {
  overflow:hidden;
  -webkit-transition:-webkit-transform 0.3s ease-out;
  transition:-webkit-transform 0.3s ease-out;
  -o-transition:transform 0.3s ease-out;
  transition:transform 0.3s ease-out;
  transition:transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  height:auto;
  -webkit-transform:scaleX(1);
      -ms-transform:scaleX(1);
          transform:scaleX(1);
  -webkit-transform-origin:top;
      -ms-transform-origin:top;
          transform-origin:top;
}

.example1-exit.example-exit-active {
  -webkit-transform:scaleX(0);
      -ms-transform:scaleX(0);
          transform:scaleX(0);
}
/* .wizard-heading{
  padding: 8px 16px 8px 16px;
  font-weight: bolder;
  border-bottom: 1px solid lightgray;

} */
