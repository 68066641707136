/* Left menu */
.agency-date-range {
  width: 45%;
}

/* Dashboard */
.card-text-lg {
  font-size: 16px !important;
  font-weight: bold;
}

.info-box.disabled {
  opacity: 0.4;
}

.gmc-suspended-footer {
  height: 60px;
  background-color: #e63946;
  border-radius: 8px;
  position: fixed;
  bottom: 22px;
  margin-left: -15px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  padding: 0 16px;
  left: 40%;
}

@-webkit-keyframes blinker {
  50% {
    opacity: 0;
  }
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.gmc-suspended-text {
  color: white;
  font-size: 12px;
  font-weight: bold;
}

.gmc-suspended-number {
  color: white;
  font-size: 12px;
  font-weight: bold;
  margin-left: 16px;
}

.btn-gmc-view-all {
  color: white;
  border-color: white;
  border-width: 1px;
  padding: 0 0.75rem;
  margin-left: 14px;
  font-size: 12px;
}
.btn-gmc-view-all:hover {
  color: #e63946;
  border-color: #e63946;
  background-color: white;
}

.performing-stores-chart-title {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
}

.chart-metrics-dropdown {
  width: 130px;
  font-size: 12px;
 }

/* Manage Agency */
.manage-agency-card-title {
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 16px;
}

.manage-agency-card-title.switch-merchant {
  margin-bottom: 36px;
}

.manage-agency-form td {
  padding-bottom: 16px;
}

.manage-agency-form-inputs {
  width: 60%;
}

/* Google Suspended Modal */
.google-suspended-modal-table {
  font-size: 14px;
}
.google-suspended-modal-table th:first-of-type {
  padding-bottom: 8px;
}

.btn-gmc-resolve {
  color: #35B7E6;
  border-color: #35B7E6;
  border-width: 1px;
  padding: 0 0.75rem;
}
.btn-gmc-resolve:hover {
  color: white;
  border-color: #35B7E6;
  background-color: #35B7E6;
}

.google-suspended-modal-table-container {
  max-height: 500px;
  overflow: scroll;
}

.google-suspended-modal-table td {
  padding-bottom: 8px;
}

/* Coming soon container */
.coming-soon{
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background-color: white;
  border-radius: 30%;
  border: 5px solid blue;
  position: absolute;
  margin-left: -15px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  padding: 16px 16px;
  left: 20%;
  top: 15%;
  z-index: 999;
}
.coming-soon-text{
  color: black;
  font-size: 16px;
  font-weight: bold;
  -webkit-animation: blinker 3s linear infinite;
          animation: blinker 3s linear infinite;
}
/* Custom light gray for disabled top row cards. */
.bg-light-grey{
  background-color: rgba(0, 0, 0, 0.05) !important;
}

/* .collapsed-bordered-table {
  border-collapse: separate;
  border-spacing: 5px 5px;
} */
