.facebook-setup {
  margin-top: 135px;;
  /* height: 200px; */
  width: 700px;
  padding-top: 20px;
}

/* Header */
.header.facebook {
  height: 60px;
}

.facebook-ads-header {
  display: -ms-flexbox;
  display: flex;
}
.facebook-ads-header > div:first-of-type {
  margin: 0 18px;
}
.facebook-ads-header div {
  font-size: 14px;
}
.facebook-ads-header div:hover {
  cursor: pointer;
}
.facebook-ads-header .active {
  font-weight: bold;
}

.facebook.status-indicator {
  margin: 2px 16px 0px 16px;
}

/* Select */
.facebook-catalogue-select {
  width: 32%;
  margin-left: 16px;
}
.facebook-ads-range-select {
  width: 20%;
}

/* Cards */
.card.facebook-catalogue {
  height: 120px;
}
.card.facebook-catalogue.placeholder {
  background-color: #CACED5;
  -webkit-box-shadow: 0px 2px 6px #0000001F;
          box-shadow: 0px 2px 6px #0000001F;
  opacity: 0.2;
}
.card.facebook-catalogue .text-number, .card.facebook-ads .text-number {
  font-size: 24px;
  font-weight: bold;
}

.card.facebook-ads {
  height: 200px;
}

/* Text */
.text-link {
  font-size: 12px;
  text-decoration: underline;
}

.campaign-subheading {
  font-size: 14px;
  height: 42px;
  font-weight: bold;
  margin-bottom: 8px;
  overflow: hidden;
  -o-text-overflow: ellipsis;
     text-overflow: ellipsis;
}

/* Icons */
.icon-text-aligned {
  vertical-align: text-bottom;
}
.facebook-widget-card-indicator-icon, .facebook-widget-card-indicator {
  font-size: 12px;
  font-weight: bold;
}
.facebook-widget-card-indicator {
  color: #333333;
}

/* Miscellaneous */
.vertical-line-header {
  border-left: 1px solid #BCBCBC;
  height: 25px;
  margin-top: -2px;
}

.vertical-line {
  border-left: 1px solid #E9E9E9;
}

.placeholder {
  background-color: #CACED5;
  -webkit-box-shadow: 0px 2px 6px #0000001F;
          box-shadow: 0px 2px 6px #0000001F;
  opacity: 0.2;
}
.placeholder.h4 {
  height: 24px;
  width: 100px;
}
.placeholder.card-long {
  height: 80px;
}

/* Table */
.facebook-ads-campaign-table-header th, .facebook-ads-campaign-table-item td {
  height: 42px;
  font-size: 14px;
}
.facebook-ads-campaign-table-item:hover td:not(:first-child) {
  background-color: #E6E6E6;
  cursor: pointer;
  padding-left: 0.25rem;
}

/* Form */
.facebook-ads-create-campaign label {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 4px;
}

.facebook-ads-create-campaign input, .facebook-ads-create-campaign .Select-control {
  border-radius: 0 !important;
  height: 40px !important;
}

.facebook-ads-create-campaign .input-group-text {
  border-radius: 0;
  height: 40px;
}