
/* Nav ================================ */

nav > .nav.nav-tabs{
    border: none;
    color:#fff;
    background:#272e38;
    border-radius:0;
}

nav > div a.nav-item.nav-link {
  border: none;
  font-size: 1.2rem;
  font-weight: 600;
  padding: 18px 0px;
  color: #378080;
  background :#fff;
  border-radius: 0;
  width: 50%;
  border-bottom: 2px solid #f5f5f5;
}

nav > div a.nav-item.nav-link:hover,
nav > div a.nav-item.nav-link.active:hover {
  background: #378080;
  color: #fff;
}

nav > div a.nav-item.nav-link.active {
  color: #378080;
  border-bottom: 3px solid #378080;
  width: 50%;
}

.tab-content {
    background: #fdfdfd;
    border: 1px solid #ddd;
}

.nav-mobile-header-text {
  line-height: 0.8;
}

/* nav > div a.nav-item.nav-link:hover,
nav > div a.nav-item.nav-link:focus {
    border: none;
    background: #378080;
    color:#fff;
    border-radius:0;
    transition:background 0.20s linear;
} */

/* #nav-home-tab, #nav-google-ads-tab {
  box-shadow: 0px 6px 8px -8px rgba(0, 0, 0, 0.2);
} */

#nav-google-ads-tab {
  border-left: 1px solid #f5f5f5;
}

.nav-menu-google {
  min-height: 600px;
  background-color: #fff;
  border-right: 1px solid #ececec;
}

#overview_panel, #accounts_panel, #feeds_panel, #MCA_Authenticated {
    display: none
}

#Google_ads_campaigns_panel, #Google_ads_accounts_panel, #Google_ads_overview_panel, #GoogleAds_Authenticated_New_Campaign, #google_ads_new_account_details, #google_ads_new_account_details_form, #GoogleAds_Authenticated {
    display: none
}

/* .tab-content > .active {
    display: flex !important;
} */

#google_ads_authentication_panel_1, #google_ads_new_account_details, #google_ads_new_account_details_form {
  -ms-flex: 1 1;
      flex: 1 1
}

/* Navbar items */
.nav-menu-item {
  color: #333333;
  font-size: 0.9rem;
  cursor: pointer;
  display: block;
  font-weight: 500;
}

.nav-menu-item:hover {
  color: #378080;
}

.nav-menu-subitem {
  color: #fff;
  font-size: 0.8rem;
  cursor: pointer;
  display: block;
}

.nav-menu-subitem:hover {
  color: #333333;
}

.nav-menu-item-container {
  margin-top: 16px;
  min-height: 400px;
  -ms-flex: 1 1;
      flex: 1 1;
}

.bgcolor-container {
  background-color: #f5f5f5;
}


.google-campaign-table-item:hover{
  background-color: #E6E6E6;
  cursor: pointer;
}

.google-campaign-table-header th, .google-campaign-table-item td {
  height: 42px;
}

.google-campaign-settings td, .google-campaign-settings-summary td {
  font-size: 14px;
}
.google-campaign-settings {
  width: 70% !important;
  margin-left: 34px;
}
.google-campaign-settings input {
  margin: 8px 0;
}

td.google-campaign-settings-label {
  width: 30%;
}

#gmc-dashboard-cards div,
#gmc-dashboard-products-cards div,
#google-ads-dashboard-cards div {
  min-height: 120px;
}

#gmc-dashboard-products-cards p {
  color: #fff;
}

#card-active-products {
  background-color: #409D57;
}
#card-expiring-products {
  background-color: #F09807;
}
#card-pending-products {
  background-color: #4484F5;
}
#card-disapproved-products {
  background-color: #DB4437;
}

#google-errors-table td {
  border: 1px solid #8d8d8d;
  padding: 4px 16px;
  background-color: #fdddb4;
  font-size: 12px;
}
  #google-errors-table th {
  background-color: #ffad42;
  border: 1px solid #8d8d8d;
  padding: 4px 16px;
  font-size: 13px;
}

#gmc-settings-table td, #gmc-settings-table th {
  border: 1px solid #8d8d8d;
  padding: 4px 16px;
  font-size: 14px;
}
#gmc-settings-table th {
  background-color: #dbdbdb;
}

#gmc-feeds-table td, #gmc-feeds-table th {
  padding: 4px 16px;
}

p.google-card {
  font-size: 22px;
  margin-bottom: 0;
  margin-top: 16px;
}

#ad-preview {
  width: 200px;
  min-height: 300px;
}
.ad-preview-name {
  font-size: 12px;
  color: #2245D6;
  margin-bottom: 8px;
  margin-top: 8px;
  overflow: hidden;
  -o-text-overflow: ellipsis;
     text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 16px;     /* fallback */
  max-height: 32px;      /* fallback */
  -webkit-line-clamp: 2; /* number of lines to show */
}
.ad-preview-price {
  font-size: 14px;
  font-weight: bold !important;
  margin-bottom: 4px;
}
.ad-preview-url {
  font-size: 14px;
  color: #177E14;
  margin-bottom: 4px;
}
